import request from "./request";

// 添加日报
export function addDaily(data) {
	return request({
		url: "daily/add",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 数据列表
export function getList(data) {
	return request({
		url: "daily/pageLimitData",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 添加日志评价
export function addDailyEval(data) {
	return request({
		url: "daily/addDailyEval",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 查看详情
export function getDetail(data) {
	return request({
		url: "daily/dataInfo",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 删除
export function detail(data) {
	return request({
		url: "daily/del",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}
