<template>
  <div class="daily-page view-page">
    <c-search searchTitle="姓名" @search="dailySearch" valueFormat="yyyyMMdd" placeholder="姓名" :isDate="true"></c-search>
    <c-navigation title="类型" @change="getDailyTypeList" :list="dailyTypeList"></c-navigation>

    <div class="flex" style="border-bottom: 1px solid #edf2f2">
      <c-navigation @change="changeDepartment" title="部门" :list="$store.state.departmentListAll"></c-navigation>
      <c-navigation @change="changeStatus" title="审批状态" :list="approvalState"></c-navigation>
    </div>
    <c-operate>
      <template #right>
        <div class="btn-list courtDate" @click="showDailyPaper = true">新建日报</div>
      </template>
    </c-operate>
    <c-table :data="tableData">
      <!-- <el-table-column align="center" label="选择">
        <template #default="{ row }">
          <el-checkbox v-model="row.isListChecked" @change="checkedClient(row)"></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="date" label="部门">
        <template #default="{ row }">
          <p>{{ row.section_id }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="username" label="姓名"> </el-table-column>
      <el-table-column align="center" prop="finishwork" label="今日完成"> </el-table-column>
      <el-table-column align="center" prop="date" label="审批状态">
        <template #default="{ row }">
          <p>{{ ['进行中', '已驳回', '已完成'][row.status] }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间" width="180"> </el-table-column>
      <el-table-column align="center" prop="date" label="操作">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list edit" @click="checkDaily(row)" :class="{ active: row.isListChecked }">查看</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label=""> </el-table-column>
    </c-table>
    <!-- 查看客户 -->
    <c-drawer v-model="showNewClient" :width="40.5" :title="`${dayTime}  日报详情`">
      <div class="newClient">
        <p class="title">#客户信息</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">部门：</p>
            <el-input class="inp" disabled size="mini" :value="dailyDetail.adminMobile" placeholder="请输入内容"></el-input>
          </div>
          <div class="w-50"></div>
          <div class="w-50">
            <p class="name">姓名：</p>
            <el-input class="inp" disabled size="mini" :value="dailyDetail.adminName" placeholder="请输入内容"></el-input>
          </div>
          <div class="w-50">
            <p class="name">联系方式：</p>
            <el-input class="inp" disabled size="mini" :value="dailyDetail.adminSection" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <p class="title">#日报明细</p>
        <div class="newClientFrom">
          <div class="w-100">
            <p class="name">已完成：</p>
            <el-input class="inp" type="textarea" disabled :rows="3" :value="dailyDetail.finishwork" :resize="'none'" size="mini" placeholder="请输入内容"></el-input>
          </div>
          <div class="w-100">
            <p class="name">未完成：</p>
            <el-input class="inp" type="textarea" disabled :rows="3" :value="dailyDetail.nowork" :resize="'none'" size="mini" placeholder="请输入内容"></el-input>
          </div>
          <div class="w-100">
            <p class="name">待协调：</p>
            <el-input class="inp" type="textarea" disabled :rows="3" :value="dailyDetail.needwork" :resize="'none'" size="mini" placeholder="请输入内容"></el-input>
          </div>
        </div>

        <p class="title">#审核人</p>
        <div class="newClientFrom approval">
          <div class="approvalDetails" v-for="(i, index) in dailyDetail.dailyEvl" :key="index">
            <div class="ww-80">
              <p class="name">{{ i.section }}：</p>
              <div class="item">
                <span class="name1">{{ i.username }}</span>
              </div>
            </div>
            <div class="ww-80">
              <p class="name">批注：</p>
              <el-input class="inp" size="mini" :value="i.remark" disabled placeholder="请输入内容"></el-input>
            </div>
          </div>

          <div class="approvalDetails">
            <div class="ww-80">
              <p class="name">抄送：</p>
              <div class="item">
                <span class="name1">{{ userName }}</span>
              </div>
            </div>
            <div class="ww-80">
              <p class="name">批注：</p>
              <el-input class="inp" size="mini" v-model="annotation.remark" placeholder="请输入内容"></el-input>
            </div>
            <p class="tips" v-if="showRemark">请输入内容</p>
          </div>
        </div>

        <div class="btn">
          <div class="cancel" @click="showNewClient = false">取消</div>
          <div class="confirm" @click="submitEvaluate">确定</div>
        </div>
      </div>
    </c-drawer>

    <!-- 新建日报 -->
    <c-drawer v-model="showDailyPaper" :width="40.61" :title="`${dayTime}  新增日报`">
      <div class="daily">
        <!-- 选择日报类型 -->
        <div class="daily-btn">
          <template v-for="(i, index) in dailyBtn">
            <div :key="index" @click="newCreateDailyParam.typelist = index" :class="{ active: newCreateDailyParam.typelist == index }">{{ i }}</div>
          </template>
        </div>
        <!-- 明细 -->
        <div class="addDetail">
          <p class="title">#{{ dailyBtn[newCreateDailyParam.typelist] }}明细</p>
          <div class="newClientFrom">
            <div class="w-100">
              <p class="name">已完成：</p>
              <el-input class="inp" type="textarea" :rows="3" :resize="'none'" v-model="newCreateDailyParam.finishwork" size="mini" placeholder="请输入内容"></el-input>
            </div>
            <div class="w-100">
              <p class="name">未完成：</p>
              <el-input class="inp" type="textarea" :rows="3" :resize="'none'" v-model="newCreateDailyParam.nowork" size="mini" placeholder="请输入内容"></el-input>
            </div>
            <div class="w-100">
              <p class="name">待协调：</p>
              <el-input class="inp" type="textarea" :rows="3" :resize="'none'" v-model="newCreateDailyParam.needwork" size="mini" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <p class="title">#审核人</p>
          <div class="selectAuditor">
            <template v-for="item in contactsData">
              <el-checkbox @change="item.isSelect = !item.isSelect" :key="item.id" border size="mini">{{ item.nickname }}</el-checkbox>
            </template>
          </div>
          <div class="btn">
            <div class="cancel" @click="showDailyPaper = false">取消</div>
            <div class="confirm" @click="confirmData">确定</div>
          </div>
        </div>
        <div></div>
      </div>
    </c-drawer>

    <!-- 分页 -->
    <c-pagination @change="changePage" :pageSize="getListParam.limit" :total="contactsTotal"></c-pagination>
  </div>
</template>

<script>
import CDrawer from '@/components/c-drawer'
import * as contactsApi from '@/api/contacts.js'
import * as dailyApi from '@/api/daily.js'
import * as commonApi from '@/api/common'
import { mapGetters } from 'vuex'

export default {
  name: 'Daily',
  components: {
    CDrawer
  },
  data() {
    return {
      dayTime: '', //今天日期
      showNewClient: false,
      showDailyPaper: false,
      dailyBtn: ['日报', '周报', '月报'],
      dailyBtnNum: 0,
      showRemark: false,
      // 部门
      input: '123',
      // 日报类型
      dailyTypeList: [
        {
          id: 0,
          name: '全部',
          isChecked: true
        },
        {
          id: 1,
          name: '我发出',
          isChecked: false
        },
        {
          id: 2,
          name: '我接收',
          isChecked: false
        }
      ],
      // 审批状态
      approvalState: [
        {
          id: 1,
          status: '',
          name: '全部',
          isChecked: true
        },
        {
          id: 2,
          status: 0,
          name: '进行中',
          isChecked: false
        },
        {
          id: 3,
          status: 1,
          name: '已驳回',
          isChecked: false
        },
        {
          id: 4,
          status: 2,
          name: '已完成',
          isChecked: false
        }
      ],
      approverList: [
        {
          title: '部门经理',
          notationTime: '2022-7-25 12:00:00',
          annotateTheContent: '继续加油',
          approver: '张三',
          isAudit: true,
          img: 'https://img2.baidu.com/it/u=4244269751,4000533845&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1658250000&t=2a5c632e59bf60640bffcd0445aa1ed0'
        }
      ],
      // 提交批注
      annotation: {
        daily_id: '',
        remark: ''
      },
      // 列表数据
      tableData: [],
      selectedTable: [],
      contactsData: [], //通讯录列表
      dailyDetail: {}, //日报详情
      contactsParam: {
        //获取通讯录列表传输数据
        usmob: '',
        section_id: '',
        status: 'zaizhi',
        page: 1,
        limit: 100
      },
      getListParam: {
        //获取数据列表传输数据
        type: 0,
        status: 0,
        search: '',
        starday: '',
        endday: '',
        section_id: '',
        page: 1,
        limit: 7
      },
      contactsTotal: 0, //总条数
      newCreateDailyParam: {
        typelist: 0,
        finishwork: '',
        nowork: '',
        needwork: '',
        send: ''
      }
    }
  },
  created() {
    this.getContacts()
    this.init()
  },
  computed: {
    ...mapGetters(['userName'])
  },
  methods: {
    async init() {
      this.getList()
    },
    /**
     * @description: 获取数据列表
     */
    getList() {
      dailyApi.getList(this.getListParam).then(res => {
        this.tableData = []
        this.contactsTotal = res.data.total
        this.tableData.push(...res.data.data)
      })
    },
    getName(i) {
      this.newCreateDailyParam.typelist = i
    },
    /**
     * 添加日志评价
     */
    submitEvaluate() {
      if (!this.annotation.remark) {
        return (this.showRemark = true)
      } else {
        this.showRemark = false
        this.annotation.daily_id = this.dailyDetail.id
        dailyApi.addDailyEval(this.annotation)
      }
    },
    /**
     * @description: 获取通讯录
     */
    getContacts() {
      contactsApi.getContacts(this.contactsParam).then(res => {
        this.contactsData = res.data.data
        this.contactsData.forEach(i => {
          i.isSelect = false
        })
      })
    },
    /**
     * 新建日报 周报 月报
     */
    confirmData() {
      if (!this.newCreateDailyParam.finishwork) return this._showMsg('请输入完成事项')
      if (!this.newCreateDailyParam.nowork) return this._showMsg('请输入未完成事项')
      if (!this.newCreateDailyParam.needwork) return this._showMsg('请输入待协调事项')
      const arr = []
      this.contactsData.forEach(i => {
        if (i.isSelect) {
          arr.push(i.id)
        }
      })
      this.newCreateDailyParam.send = arr.join(',')
      if (!this.newCreateDailyParam.send) return this._showMsg('请选择审批人')

      dailyApi.addDaily(this.newCreateDailyParam).then(res => {
        this.newCreateDailyParam = {
          typelist: 0,
          finishwork: '',
          nowork: '',
          needwork: '',
          send: ''
        }
      })
      this.showDailyPaper = false
      this.getList()
    },
    // 切换导航 部门
    changeDepartment(e) {
      this.getListParam.section_id = e.id
      this.getList()
    },
    /**
     * 切换导航  类型
     */
    getDailyTypeList(e) {
      this.getListParam.type = e.id
      this.getList()
    },
    // 切换导航 状态
    changeStatus(e) {
      this.getListParam.status = e.status
      this.getList()
    },
    /**
     * 关键字搜索
     */
    dailySearch(e) {
      this.getListParam.search = e.keyWord
      if (e.date) {
        this.getListParam.starday = e.date[0]
        this.getListParam.endday = e.date[1]
      } else {
        this.getListParam.starday = ''
        this.getListParam.endday = ''
      }
      this.getList()
    },
    // 选中的项
    checkedClient(row) {
      let idx
      this.selectedTable.forEach((item, index) => {
        if (item.id === row.id) {
          idx = index
        }
      })
      if (idx >= 0) {
        this.selectedTable.splice(idx, 1)
      } else {
        this.selectedTable.push(row)
      }
    },

    // 查看日报详情
    checkDaily(row) {
      this.annotation.daily_id = ''
      this.annotation.remark = ''
      let date = new Date()
      this.dayTime = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}-${date.getDate().toString().padStart(2, 0)}`
      if (this.dayTime) {
        this.showNewClient = true
      }
      dailyApi.getDetail({ id: row.id }).then(res => {
        this.dailyDetail = res.data
      })
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.getListParam.page = i
      this.getList()
    }
  }
}
</script>

<style>
.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
  margin-top: 1rem;
}
</style>
<style lang="scss" scoped>
.daily-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}

.courtDate {
  background: linear-gradient(230deg, #fc9940, #f37902);
}
.table-operate {
  display: flex;
  align-items: center;
  justify-content: center;
  .edit {
    color: #333333;
  }
  .active {
    color: #f47a05;
  }
}

.newClient,
.addDetail {
  .title {
    width: 40rem;
    padding: 0 1.5rem;
    font-size: 0.94rem;
    font-weight: 400;
    color: #333333;
    line-height: 1;
  }
  .newClientFrom {
    padding: 1.55rem 0.5rem;
    display: flex;
    flex-wrap: wrap;

    .w-50 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 50%;
    }
    .w-80 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 80%;
    }
    .w-100 {
      display: flex;
      align-items: flex-start;
      margin: 0.5rem 0;
      width: 100%;
    }

    .name {
      font-size: 0.94rem;
      flex-shrink: 0;
      text-align: right;
      width: 5.5rem;
      color: #333;
    }
    .inp {
      margin: 0 2rem 0 0.8rem;
      flex-grow: 1;
    }
    .align-start {
      align-items: flex-start;
    }
  }
  .approvalDetails {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
    .ww-80 {
      display: flex;
      align-items: center;
      width: 80%;
      .item {
        width: 23.12rem;
        height: 2.61rem;
        border: 1px solid #dddcdc;
        border-radius: 0.21rem;
        padding: 0.52rem 0.73rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin: 0 2rem 0 0.8rem;
        img {
          width: 1.57rem;
          height: 1.57rem;
          border-radius: 50%;
          margin-right: 0.83rem;
        }
        span {
          margin-right: 0.83rem;
        }
      }
    }
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4.5rem 0 20rem;
    .btn {
      text-align: center;
      font-size: 0.94rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 2.2rem;
      width: 11.25rem;
      height: 2.2rem;
      background: linear-gradient(215deg, #1db8ab, #179d92);
      border-radius: 0.78rem;
    }
  }
}
.addDetail {
  margin-top: 1.41rem;
  .title {
    padding: 0;
  }
}
.daily {
  padding: 2.03rem;

  &-btn {
    display: flex;
    justify-content: center;
    div {
      padding: 0.63rem 2.19rem;
      background: #fefaf6;
      border: 1px solid #eeeeee;
      font-size: 0.94rem;
      font-weight: 400;
      color: #000000;
    }
    div:first-child {
      border-radius: 1rem 0rem 0rem 1rem;
    }
    div:last-child {
      border-radius: 0rem 1rem 1rem 0rem;
    }
    .active {
      font-size: 0.94rem;
      font-weight: 400;
      color: #ffffff;
      background: linear-gradient(109deg, #fc9940, #f37902);
    }
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 3.05rem 0;
  div {
    width: 5.56rem;
    height: 2.2rem;
    text-align: center;
    line-height: 2.22rem;
    background: #e5ebeb;
    border-radius: 0.78rem;
    color: #000000;
    font-weight: 400;
    font-size: 0.94rem;
  }
  .confirm {
    background: linear-gradient(46deg, #1db8ab, #179d92);
    margin-left: 3.75rem;
    color: #ffffff;
  }
}
.selectAuditor {
  margin-top: 1rem;
}
.tips {
  color: red;
  padding: 0.5rem 0;
}
</style>
